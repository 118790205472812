import React from 'react'

const general = [
  {
    question: 'What is TAYL?',
    answer:
      'TAYL (Talk At You Later), pronounced "tale", is a service that converts text to podcasts. It uses state of the art machine learning and artificial intelligence to sound almost human.',
  },

  {
    question: 'Why use TAYL?',
    answer:
      'For most, listening is easier than reading when multi-tasking. You can easily listen while at the gym, on your bike or on your commute. By listening you also avoid screen-time and can spend more time outside, which research has proven is very healthy for us! TAYL can also help you collect texts that you want to read, but "not right now" - listen when you feel like, just as you would listen to any other Podcast.',
  },

  {
    question: 'How long does it take?',
    answer:
      'That depends on serveral factors, such as the language and the length of the text, but the mean time for converting is less than 2 minutes. Once converted to audio it is instantly delivered to your Podcast.',
  },

  {
    question: 'Who reads all the text?',
    answer: 'Computers, of course! No humans involved, yet.',
  },

  {
    question: 'What data do you collect?',
    answer: (
      <>
        No more data is collected than what is needed to run and improve this
        service. For a full declaration of the data collected, please read{' '}
        <a
          href="https://www.tayl.app/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy & Terms
        </a>
        .
      </>
    ),
  },

  {
    question: 'How do I subscribe to my Podcast?',
    answer: (
      <>
        Most (if not all) podcast apps support subscribing to a podcast feed
        from URL. Search the Internet for specific instructions for your app, or
        see{' '}
        <a
          href="https://medium.com/@joshmuccio/how-to-manually-add-a-rss-feed-to-your-podcast-app-on-desktop-ios-android-478d197a3770"
          target="_blank"
          rel="noopener noreferrer"
        >
          this guide
        </a>{' '}
        for some of the most popular apps.
      </>
    ),
  },
]

export default general
