import React from 'react'
import Obfuscate from 'react-obfuscate'
import Layout from '../components/Layout.js'
import Container from '../components/Container.js'
import Footer from '../components/Footer.js'
import FAQ from '../components/FAQ.js'
import faqGeneral from '../data/faq/general.js'
import faqVoices from '../data/faq/voices.js'
import faqWebExtensions from '../data/faq/webExtensions.js'
import faqBilling from '../data/faq/billing.js'
import styles from '../templates/ContentPageTemplate.module.sass'
import './index.sass'

const Support = () => (
  <Layout isHeaderFilled>
    <div className={styles.contentPage}>
      <Container>
        <div className={styles.runningText}>
          <h1>Support</h1>
          <p>
            Need to get in touch? Send an e-mail to{' '}
            <Obfuscate email="support@tayl.app" />. Alternatively, send a DM to
            <a
              href="https://twitter.com/miickel"
              target="_blank"
              rel="noopener noreferrer"
            >
              @miickel
            </a>{' '}
            on Twitter or sign in with your account and visit the{' '}
            <a href="https://my.tayl.app/support">Support Center</a> to get
            priority support.
          </p>
          <div>
            <h3>General</h3>
            {faqGeneral.map(renderFaq)}
            <h3>Languages & Voices</h3>
            {faqVoices.map(renderFaq)}
            <h3>Web extensions</h3>
            {faqWebExtensions.map(renderFaq)}
            <h3>Billing</h3>
            {faqBilling.map(renderFaq)}
          </div>
        </div>
      </Container>
    </div>
    <Footer />
  </Layout>
)

function renderFaq(faq, i) {
  return <FAQ key={i} {...faq} />
}

export default Support
