import React, { useState } from 'react'
import classnames from 'classnames'
import SvgIcon from '@material-ui/core/SvgIcon'
import IconRight from '@material-ui/icons/KeyboardArrowRight'
import IconDown from '@material-ui/icons/KeyboardArrowDown'
import styles from './FAQ.module.sass'

const FAQ = ({ id, question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleQuestionClick = () => {
    setIsExpanded(!isExpanded)
  }

  const cl = classnames({
    [styles.faq]: true,
    [styles.isExpanded]: isExpanded,
  })

  const Icon = isExpanded ? IconDown : IconRight

  return (
    <div className={cl}>
      <button onClick={handleQuestionClick} className={styles.question}>
        <SvgIcon>
          <Icon />
        </SvgIcon>
        {question}
      </button>
      <div className={styles.answer}>
        <div>{answer}</div>
      </div>
    </div>
  )
}

export default FAQ
