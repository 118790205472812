import React from 'react'
import { Link } from 'gatsby'

const voiceAnswer = (
  <>
    For a full list of the supported languages and voices, please visit{' '}
    <Link to="/voices">tayl.app/voices</Link>.
  </>
)

const voices = [
  {
    question: 'What languages do you support?',
    answer: voiceAnswer,
  },

  {
    question: 'What voices can I select from?',
    answer: voiceAnswer,
  },

  {
    question: 'What is Standard vs. Premium (HQ) voices?',
    answer:
      'Standard voices are cheaper but are of lower quality than Premium voices. For Standard voices you pay 1 credit per 100 characters, and for Premium voices you pay 4 credits per 100 characters.',
  },

  {
    question: 'When will you add language XYZ?',
    answer:
      'While I plan to add many more languages and voices, I cannot answer exactly when these will become available. New languages will be announced via the Newsletter. Stay tuned!',
  },

  {
    question: 'Can TAYL do translations?',
    answer: 'Not yet, but this is a planned feature!',
  },
]

export default voices
