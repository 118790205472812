import React from 'react'

const billing = [
  {
    question: 'Do you offer a FREE plan?',
    answer:
      'No, not at the moment. This might change in the future, but right now an active payed subscription is required to fully use the service.',
  },

  {
    question: 'Do you offer volume discounts?',
    answer:
      'Yes, I offer a multi-user volume discount for groups of 10+, billing annually. Get in touch for more information.',
  },

  {
    question: 'How does the usage quota work?',
    answer:
      'The number of characters for each Tale you submit, that successfully converts to audio without any errors, will be added to your monthly quota. Standard voices require 1 credit per 100 characters. Premium voices require 4 credits per 100 characters. The credit quota resets at the end of each billing period.',
  },

  {
    question: 'How does credits work?',
    answer:
      'Select voices from two variants: Standard or Premium. Premium voices are suffixed with (HQ). To convert 100 characters using Standard voices, you pay 1 credit. To convert 100 characters using Premium voices, you pay 4 credits.',
  },

  {
    question: 'Where can I monitor my usage?',
    answer: (
      <>
        You can monitor your usage in the{' '}
        <a href="https://my.tayl.app/billing/subscription-usage">
          Subscription usage history
        </a>
        .
      </>
    ),
  },

  {
    question: 'Can I use more than the credits included?',
    answer:
      'Yes. Overage credits will be billed separately at the end of the billing period. The rate is $0.001 per credit.',
  },

  {
    question: 'How do I change plan or billing scheme?',
    answer: 'Contact support and I will help you out!',
  },

  {
    question: 'How do I cancel my subscription?',
    answer: (
      <>
        You can cancel your subscription in the{' '}
        <a href="https://my.tayl.app/billing/subscription-details">
          Subscription Details
        </a>
        . If you cancel, you are free to continue to use the service until the
        end of the billing period.
      </>
    ),
  },
]

export default billing
